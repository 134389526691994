.page {
    min-height: calc(100vh - 60px);
    padding: 80px 20px 40px 20px;
    position: relative;
    overflow: visible;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
}

@media (max-width: 800px) {
    .page#pageHero {
        /* Extra space for the browser bar overlay */
        min-height: calc(100vh - 160px);
    }
}

.pageInner {
    max-width: 800px;
    /* Initial value for transform */
    transform: translateY(200px);
}

.pageInner#pageInnerHero {
    transform: translateY(0);
    width: 100%;
}

#heroSpacer {
    background-image: url("/images/logo-horizontal.png");
    background-size: 100% auto;
    background-repeat: no-repeat;
    background-position: center center;
    width: 100%;
    padding-top: 25%;
    margin: 0 auto;
}

@media (max-width: 800px) {
    #heroSpacer {
        background-image: url("/images/logo-vertical.png");
        padding-top: 52%;
        max-width: 480px;
    }
    #heroTagline {
        margin-top: 18pt;
    }
}

.orange {
    background-color: #ffbb55;
    color: #474747;
}
.orange .header {
    color: #000000;
}
.orange strong {
    color: #000000;
}
.orange .borderBox {
    border-top-color: #ffbb55;
}
.gray {
    background-color: #474747;
    color: #F3D7AC;
}
.gray .borderBox {
    border-top-color: #474747;
}
.gray .header, .gray .subheader {
    color: #ffbb55;
}
.white {
    background-color: #ffffff;
    color: #474747;
}
.white .borderBox {
    border-top-color: #ffffff;
}
.white .subheader {
    color: #ffbb55;
}

.flexCenter {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
}
.flexCenter.start {
    justify-content: flex-start;
    align-content: center;
    padding-top: 4vh;
}

.flexTwoColumn {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.flexColumn {
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex-basis: 320px;
    flex-grow: 1;
    flex-shrink: 1;
    flex-wrap: wrap;
}

.borderBox {
    width: 0px;
    height: 0px;
    border-width: 40px 120px;
    border-color: transparent;
    box-sizing: content-box;
    border-style: solid;
    position: absolute;
    bottom: 0;
    left: 50%;
    margin-left: -120px;
    margin-bottom: -80px;
    z-index: 1;
}

.noChevron .borderBox {
    display: none;
}
