.App {
  text-align: left;
  font-family: 'Yanone Kaffeesatz', sans-serif;
  font-size: 16pt;
}

p {
  margin-bottom: 4pt;
}

.nowrap {
  white-space: nowrap;
}

.header {
  font-size: 40pt;
  max-width: 80vw;
  margin-bottom: 24pt;
}

.header.center {
  text-align: center;
  margin-left: auto;
  margin-right: auto;
}

.subheader {
  font-size: 24pt;
  margin-top: 24pt;
}

.hero-logo {
  width: 600px;
  max-width: 90vw;
  margin: 0 auto 24pt auto;
}

.mockupImage {
  width: 100%;
  max-width: 320px;
}

.center {
  text-align: center;
  align-items: center;
}

.right {
  text-align: right;
}

.flexGrid {
  width: 100%;
  max-width: 800px;
  margin: 24pt auto 0 auto;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
}

.flexGrid.justify {
  justify-content: space-around;
}
.flexGrid.center {
  justify-content: center;
}

.sectionLink {
  color: #ffbb55;
  padding: 8pt 0;
  cursor: pointer;
  font-size: 20pt;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 2px;
  flex-basis: 25%;
  text-align: center;
}

@media ( max-width: 800px ) {
  .sectionLink {
    flex-basis: 50%
  }
}

.sectionLink:hover:not(.selected) {
  text-decoration: underline;
  color: #f3d7ac;
}

.sectionLink.selected {
  text-decoration: underline;
  color: #ffffff;
}

.tenetContainer {
  min-height: 200px;
}

.tenet {
  margin-top: 8pt;
  padding: 18pt 8pt;
  border-radius: 4px;
  line-height: 1.4;
  text-align: justify;
  background-color: #f3d7ac;
  color: #000;
  font-family: Arial;
  font-size: 14pt;
}

.contactContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.contactLogo {
  width: 80px;
  max-width: 20%;
  margin: 8pt 0;
}

.contactText {
  margin-left: 8pt;
  font-size: 24pt;
  color: #000000;
  text-decoration: none;
  white-space: nowrap;
}
@media (max-width: 800px) {
  .contactText {
    font-size: 20pt;
  }
  .contactLogo {
    width: 60px;
  }
}
a.contactText:hover {
  color: #ffbb55;
}

.footer {
  z-index: 2;
  text-align: center;
  font-size: 12pt;
  color: #ffbb55;
  padding-bottom: 12pt;
}