.conceptContainer {

    flex-basis: 25%;
}
.conceptComponent {
    width: 140px;
    margin: 14pt auto;
    text-align: center;
    font-size: 14pt;
    text-align: justify;
    box-sizing: content-box;
}

@media ( max-width: 800px ) {
    .conceptContainer {
      flex-basis: 50%
    }
  }

.conceptCode {
    font-size: 12pt;
    color: #474747;
    margin-top: 8pt;
    text-align: center;
}
.conceptName {
    font-size: 24pt;
    color: #000000;
    margin: 4pt auto 8pt auto;
    text-align: center;
}
.conceptImageContainer {
    width: 100%;
    height: 0;
    padding-top: 100%;
    position: relative;
}

.conceptImage {
    position: absolute;
    top: 0; right: 0; left: 0; bottom: 0;
    background-position: 50% 50%;
    background-size: 120% 120%;
    transition: background-size 200ms;
}

.conceptMask {
    background-size: 100% 100%;
    z-index: 2;
}

.conceptMask:hover + .conceptImage {
    background-size: 130% 130%;
}